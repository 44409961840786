<div class="content-dashboard">
    <div class="title-module">
        <span>Administrador de Usuarios</span>
    </div>
    <div class="boddy-module" fxLayout="column">
        <div class="status-widgets">
            <app-card-status [dataSource]="dataSouceWidget"> </app-card-status>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
            <div class="card-list" fxFlex="100">
                <mat-card class="example-card">
                    <mat-card-header class="title-modules" fxLayout="row">
                        <div class="title" fxFlex="30">
                            <mat-card-title>Listado de Usuarios</mat-card-title>
                        </div>
                        <div class="actions" fxLayout="row" fxLayoutAlign="end center" fxFlex="80">
                            <mat-form-field class="filter-input" appearance="outline">
                                <mat-label>Filtro</mat-label>
                                <input matInput placeholder="Ingrese un nombre" (keyup)="applyFilter($event)">
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>
                            <div class="add-action" fxLayoutAlign="center center">
                                <button mat-button class="add-btn" fxLayout="row" fxLayoutAlign="center center"
                                    (click)="openManagerUser()">
                                    <mat-icon>add_circle</mat-icon>
                                    <span>Nuevo</span>
                                </button>
                            </div>
                        </div>
                    </mat-card-header>
                    <mat-card-content>

                        <table mat-table [dataSource]="usersDataSource">
                            <ng-container matColumnDef="nombres">
                                <th mat-header-cell *matHeaderCellDef> Nombres </th>
                                <td mat-cell *matCellDef="let element"> {{element.name}} {{element.lastName}} </td>
                            </ng-container>
                            <ng-container matColumnDef="correo">
                                <th mat-header-cell *matHeaderCellDef> Correo </th>
                                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                            </ng-container>

                            <ng-container matColumnDef="documento">
                                <th mat-header-cell *matHeaderCellDef> No Documento</th>
                                <td mat-cell *matCellDef="let element"> {{element.numIdentification}} </td>
                            </ng-container>
                            <ng-container matColumnDef="roll">
                                <th mat-header-cell *matHeaderCellDef> Roll </th>
                                <td mat-cell *matCellDef="let element"> {{element.rol.name}} </td>
                            </ng-container>
                            <ng-container matColumnDef="estado">
                                <th mat-header-cell *matHeaderCellDef> Estado </th>
                                <td mat-cell *matCellDef="let element"> {{element.status.name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="acciones">
                                <th mat-header-cell *matHeaderCellDef> Acciones </th>
                                <td mat-cell *matCellDef="let element">
                                    <button mat-icon-button [matMenuTriggerFor]="menu"
                                        aria-label="Example icon-button with a menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="openUserEditModal(element)">
                                            <mat-icon>dialpad</mat-icon>
                                            <span>Editar</span>
                                        </button>
                                        <button mat-menu-item (click)="openDisableUserModal(element)">
                                            <mat-icon>settings_power</mat-icon>
                                            <span>Desabilitar</span>
                                        </button>
                                        <button mat-menu-item (click)="openUserDeletedModal(element)">
                                            <mat-icon>delete</mat-icon>
                                            <span>Eliminar</span>
                                        </button>                                  
                                    </mat-menu>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="usersDataTitle"></tr>
                            <tr mat-row *matRowDef="let row; columns: usersDataTitle;"></tr>
                        </table>

                        <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons
                            aria-label="Select page of periodic elements">
                        </mat-paginator>

                    </mat-card-content>
                </mat-card>
            </div>

        </div>
    </div>
</div>