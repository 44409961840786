<mat-card class="content-card">
  <mat-card-header class="content-titles">
    <mat-card-title class="title">Detalle de Orden de Servicio</mat-card-title>
    <mat-card-subtitle class="subtitles-titles">Administración y gestion de orden de servicios.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content class="ng-form-content" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="5px">
    <mat-tab-group dynamicHeight class="content-tabs">
      <mat-tab label="Información General">
        <div class="example-small-box mat-elevation-z4">
          <section class="company-info" fxLayout="column">

            <section class="" fxLayoutGap>
              <div class="title-info">
                <mat-card-subtitle class="subtitles">Asignación de Orden</mat-card-subtitle>
              </div>
              <hr />

              <div class="" fxLayout="row">
                <div class="content-info" fxLayout="column">
                  <label class="item-colaborator-text" fxFlex="100" *ngIf="!data.assignedUser">Selecciones un usuario de
                    la lista para asignarle la orden de servicio:</label>
                  <label class="item-colaborator-text" fxFlex="100" *ngIf="data.assignedUser">Esta orden de servicio se
                    encuentra asignado y esta siendo gestionada por el usuario:</label>
                </div>
                <div class="content-info" fxLayout="column">
                  <form [formGroup]="selectColaborator">
                    <mat-form-field fxFlex="100">
                      <mat-label>Seleccione un colaborador</mat-label>
                      <mat-select formControlName="usersCollaboratorField">
                        <mat-option *ngFor="let item of usersCollaborator" [value]="item">
                          {{item.name}} {{item.lastName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </form>
                </div>
              </div>
            </section>

            <section class="">
              <div class="title-info">
                <mat-card-subtitle class="subtitles">Detalle del servicio:</mat-card-subtitle>
              </div>
              <hr />

              <div class="" fxLayout="row">
                <div class="content-info" fxLayout="column">
                  <label fxFlex="100">Descripcion:</label>
                  <span fxFlex>{{data.description}}</span>
                </div>
              </div>

              <div class="" fxLayout="row">
                <div class="content-info" fxLayout="column">
                  <label fxFlex="100">Tipo de Servicio:</label>
                  <span>{{data.serviceType.name}}</span>
                </div>
                <div class="content-info" fxLayout="column">
                  <label fxFlex="100">Coordinador :</label>
                  <span>{{data.coordinatorName}}</span>
                </div>
              </div>

              <div class="" fxLayout="row">
                <div class="content-info" fxLayout="column">
                  <label fxFlex="100">Telefono Coordinador:</label>
                  <span>{{data.coordinatorPhone}}</span>
                </div>
                <div class="content-info" fxLayout="column">
                  <label fxFlex="100">Numeros de empleados:</label>
                  <span>{{data.numberWorkers}}</span>
                </div>
              </div>

              <div class="" fxLayout="row">
                <div class="content-info" fxLayout="column">
                  <label fxFlex="100">Nivel de Riesgo: </label>
                  <span>{{data.serviceRiskLevel}}</span>
                </div>
              </div>
            </section>

            <section class="">
              <div class="title-info">
                <mat-card-subtitle class="subtitles">Informacion de solicitante:</mat-card-subtitle>
              </div>
              <hr />

              <div class="" fxLayout="row">
                <div class="content-info" fxLayout="column">
                  <label fxFlex="100">Nombres:</label>
                  <span>{{data.applicantUser.applicantName}}</span>
                </div>
                <div class="content-info" fxLayout="column">
                  <label fxFlex="100">Correo:</label>
                  <span>{{data.applicantUser.applicantEmail}}</span>
                </div>
              </div>

              <div class="" fxLayout="row">
                <div class="content-info" fxLayout="column">
                  <label fxFlex="100">Telefono:</label>
                  <span>{{data.applicantUser.applicantPhone}}</span>
                </div>
              </div>
            </section>

            <section class="" fxLayoutGap>
              <div class="title-info">
                <mat-card-subtitle class="subtitles">Empresa Solicitante:</mat-card-subtitle>
              </div>
              <hr />

              <div class="" fxLayout="row">
                <div class="content-info" fxLayout="column">
                  <label fxFlex="100">Nombres Compañia:</label>
                  <span>{{data.applicantCompany.name}}</span>
                </div>
                <div class="content-info" fxLayout="column">
                  <label fxFlex="100">Nit Empresa:</label>
                  <span>{{data.applicantCompany.nit}}</span>
                </div>
              </div>

              <div class="" fxLayout="row">
                <div class="content-info" fxLayout="column">
                  <label fxFlex="100">Telefonos</label>
                  <span>{{data.applicantCompany.phone}}</span>
                </div>
                <div class="content-info" fxLayout="column">
                  <label fxFlex="100">Dirección:</label>
                  <span>{{data.applicantCompany.address}}</span>
                </div>
              </div>

            </section>

          </section>

        </div>
      </mat-tab>
      <mat-tab label="Formularios">
        <div>
          <mat-accordion>
            <mat-expansion-panel *ngFor="let item of dynamicFormsService" (opened)="panelOpenState = true"
              (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ item.name }}
                </mat-panel-title>
                <!-- <mat-panel-description>
                    Currently I am {{panelOpenState ? 'open' : 'closed'}}
                  </mat-panel-description> -->
              </mat-expansion-panel-header>

              <div class="content=form">
                <p>{{item.description}}</p>
                <mat-card appearance="outlined">
                  <mat-card-content>
                  </mat-card-content>
                </mat-card>
              </div>

            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
  <mat-dialog-actions align="end" class="content-actions">
    <button mat-button mat-dialog-close class="neutral-btn" (click)="closeModal()">Cancelar</button>
    <button mat-button cdkFocusInitial class="primary-btn" color="primary" type="submit">Guardar</button>
  </mat-dialog-actions>
</mat-card>