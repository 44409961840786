import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminsModule } from './modules/admins/admins.module';
import { AccessManagementModule } from './modules/access-management/access-management.module';
import { ExternalOrdersModule } from './modules/external-orders/external-orders.module';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'access/sing-in',
    pathMatch: 'full'
  },
  {
    path: 'access',
    component: AccessManagementModule,
    loadChildren: () => import('./modules/access-management/access-management.module').then(m => m.AccessManagementModule)
  },
  {
    path: 'main',
    component: AdminsModule,
    loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule)
  },
  {
    path: 'admin',
    component: AdminsModule,
    loadChildren: () => import('./modules/admins/admins.module').then(m => m.AdminsModule)
  },
  {
    path: 'service-order',
    component: ExternalOrdersModule,
    loadChildren: () => import('./modules/external-orders/external-orders.module').then(m => m.ExternalOrdersModule)
  },
  // { path: 'dashboard', component: DashboardComponent},
  // { path: 'profile', component: ProfileComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
