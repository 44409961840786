import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResult } from '../utilities/interfaces/result-interface';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { StatusWidget } from '../utilities/interfaces/status-widget';
import { ServiceOrderEntitie } from '../utilities/entities/service-order.entitie';
import { ServiceTypeInterface } from '../utilities/interfaces/servicetype-interface';
import { DynamicFormsServiceInterface } from '../utilities/interfaces/dynamic-forms-services.interface';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(
    private http: HttpClient
  ) { }

  public getGeneralStatusService(params: any): Observable<StatusWidget[]> {
    let url = environment.services_api + 'getGeneralStatusService';
    return this.http.get<StatusWidget[]>(url, { params: params });
  }

  public GetServicesTypes(params : any ) : Observable<ServiceTypeInterface[]>{
    let uri = environment.services_api + 'ServicesType/GetServicesType';
    return  this.http.get<ServiceTypeInterface[]>(uri, {params: params});
  }

  public getServicesByUSer(params: any): Observable<any> {
    let url: string = environment.services_api + 'Service/GetServicesByUser';
    return this.http.get<any>(url, { params: params });
  }

  public getServicesByIdCompany(params: any): Observable<any> {
    let url: string = environment.services_api + 'Service/GetServicesByCompany';
    return this.http.get<any>(url, { params: params });
  }

  public GenerateServiceOrder(params: ServiceOrderEntitie): Observable<ServiceOrderEntitie> {
    let url = environment.services_api + 'service/GenerateServiceOrder';
    return this.http.post<ServiceOrderEntitie>(url, params);
  }

  public getDynamicsFormsService(params: any): Observable<DynamicFormsServiceInterface[]> {
    let url = environment.services_api + 'DinamycForms/GetDynamycFormsServiceOrder';
    return this.http.get<DynamicFormsServiceInterface[]>(url, {params : params});
  }

  public getDetailDynamicsFormsService(params: any): Observable<DynamicFormsServiceInterface[]> {
    let url = environment.services_api + 'DinamycForms/GetDynamycFormsServiceOrder';
    return this.http.get<DynamicFormsServiceInterface[]>(url, {params : params});
  }
}
