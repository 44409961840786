import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SingInComponent } from './sing-in/sing-in.component';
import { SingUpComponent } from './sing-up/sing-up.component';
import { AccessManagementComponent } from './access-management.component';

const routes: Routes = [
  { 

    path: 'access',
    component: AccessManagementComponent,
    children: [
      {
        path: 'sing-in',
        component : SingInComponent
      },
      {
        path: 'sing-up',
        component: SingUpComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccessManagementRoutingModule { }
