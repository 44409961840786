<mat-card class="content-card">
   <app-service-order-form 
        [companie]="userData.company" 
        [userSession]="userData"
        [title]="" 
        [subTitle]="" 
        [buttonName]="'Guardar'"
        (servicesOrderEvent)="ValidationServiceOrder($event)"
        >
        
    </app-service-order-form>  
</mat-card>