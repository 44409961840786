import { Component, OnInit } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { UserInterface } from 'src/app/utilities/interfaces/user-interface';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.less']
})
export class NavbarComponent implements OnInit {
  
  public sesionUsser : any ;
  constructor(
    private router: Router
  ) { }


  ngOnInit(): void {
    let user = localStorage.getItem('user');
    this.sesionUsser = JSON.parse((user != null) ?user : "");
  }

  public redirectoToProfile():void {
    this.router.navigate(['/profile']);
  }

  public closeSesion() :void {
    localStorage.clear();
    this.router.navigate(['access/sing-in']);
  }

}
