
<form class="sing-in-form" [formGroup]="loginFormGroup" (ngSubmit)="validationUser()">
    <mat-card class="content-form">

      <mat-card-header class="content-title">
        <mat-card-title class="title">Iniciar sesión</mat-card-title>
        <mat-card-subtitle class="subtitle">Ingresa los siguientes datos y comienza una nueva
          experienza</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content class="content-ipunts" fxLayout="column">

        <mat-form-field appearance="outline">
          <mat-label>Correo electronico</mat-label>
          <input matInput type="email" formControlName="emailField" required placeholder="Porfavor ingrese su correo">
          <mat-icon matSuffix aria-hidden="false" fontIcon="mail"></mat-icon>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Contraseña</mat-label>
          <input matInput type="password" formControlName="passwordField" required
            placeholder="Porfavor ingrese su contraseña">
          <mat-icon matSuffix aria-hidden="false" fontIcon="lock"></mat-icon>
        </mat-form-field>
        <a fxLayoutAlign="end center" href=""> Olvide mi contraseña</a>

      </mat-card-content>

      <mat-card-actions class="content-btn" fxLayout="row" fxLayoutAlign="center center">
        <button mat-raised-button color="primary" class="ui-button-primary btn-login">Iniciar</button>
      </mat-card-actions>
    </mat-card>
  </form>