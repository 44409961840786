import { Component, Inject, ChangeDetectionStrategy} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Observer } from 'rxjs';
import { ServicesService } from 'src/app/services/services.service';
import { UserService } from 'src/app/services/user.service';
import { DynamicFormsServiceInterface } from 'src/app/utilities/interfaces/dynamic-forms-services.interface';
import { UserInterface } from 'src/app/utilities/interfaces/user-interface';

export interface ExampleTab {
  label: string;
  content: string;
}

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.less']
})
export class OrderDetailsComponent {
    
  public usersCollaborator : UserInterface[] = [];
  public selectColaborator : FormGroup = this.createSelectFrom();
  public panelOpenState = false;
  public dynamicFormsService : DynamicFormsServiceInterface[] = [];

  constructor(
    public dialog: MatDialog,
    public translate: TranslateService,
    public userService : UserService,
    public servicesService : ServicesService,
    @Inject(MAT_DIALOG_DATA) public data: any | null
  ) {
    
  }

  ngOnInit(): void {
    console.log(this.data);
    this.getCollaboratorsUser();
    this.getDynamicsFormsService();
  }

  public closeModal(){
    this.dialog.closeAll();
  }

  public createSelectFrom():FormGroup {
    return this.selectColaborator = new FormGroup({
      usersCollaboratorField: new FormControl(''),
    });
  } 

  public setCollaboratorAsignation(){
    if(this.data != null){
      this.selectColaborator.get('usersCollaboratorField')?.setValue(this.usersCollaborator.filter(x => x.id == this.data.assignedUser.id)[0]);
    }
  }

  public getCollaboratorsUser(): void{
    let params = {
      idCompany : this.data.createdCompanyId
    }
   this.userService.GetcollaboratorUsersByIdCompany(params).subscribe({
    next: (res: UserInterface[]) => {
      this.usersCollaborator = res;
      this.setCollaboratorAsignation();
    },
    error: (e) => console.error(
      //this.alertsHelper.error(e)
    )
   });
  }

  public getDynamicsFormsService() : void{
    let params = {
      idCompany : this.data.createdCompanyId,
      idServiceType : 'ba5ec468-b131-4b19-aab1-b2a54f44d54b'
    }

    this.servicesService.getDynamicsFormsService(params).subscribe({
      next: (res: DynamicFormsServiceInterface[]) => {
        this.dynamicFormsService = res;
      },
      error: (e) => console.error(
        //this.alertsHelper.error(e)
      )
    });
  }

}
