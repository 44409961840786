import { Component, OnInit } from '@angular/core';
import { StatusWidget } from 'src/app/utilities/interfaces/status-widget';
import { TableDataSource } from 'src/app/utilities/interfaces/table-data-source';

export interface PeriodicElement {
  nombres: string;
  correo: string;
  roll: string;
  estado: string;
}

export interface ServicesElement {
  solicitante: string;
  prestador: string;
  direccion: string;
  estado: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { nombres: 'Hydrogen', correo : 'test@pruebas.com', roll: 'Colaborador', estado : 'activo' },
  { nombres: 'Helium', correo : 'test@pruebas.com', roll: 'Administrador', estado : 'activo'},
  { nombres: 'Lithium', correo : 'test@pruebas.com', roll: 'Colaborador', estado : 'activo'},
  { nombres: 'Beryllium', correo : 'test@pruebas.com', roll: 'Administrador', estado : 'activo'},
  { nombres: 'Boron', correo : 'test@pruebas.com', roll: 'Administrador', estado : 'activo'}
];

const ELEMENT_DATA_SERVICE: ServicesElement[] = [
  { solicitante: 'Hydrogen', prestador : 'Hydrogen', direccion: 'Calle falsa 123', estado : 'activo' },
  { solicitante: 'Helium', prestador : 'Hydrogen', direccion: 'Calle C #123 n 23', estado : 'activo'},
  { solicitante: 'Lithium', prestador : 'Beryllium', direccion: 'Av simpre viva Sprintfiel', estado : 'Pausado'},
  { solicitante: 'Beryllium', prestador : 'Beryllium', direccion: 'Av limones', estado : 'activo'},
  { solicitante: 'Boron', prestador : 'Boron', direccion: 'Lomas Marias', estado : 'activo'}
];

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit  {

  public dataSouceWidget : StatusWidget[] = [];

  public dataUsers : TableDataSource<PeriodicElement[]> = {
    titles : [],
    dataSource : []
  };

  public dataServices : TableDataSource<ServicesElement[]> = {
    titles : [],
    dataSource : []
  };

  ngOnInit(): void {
    this.generateStatusWidget(null);
    this.setDataUser();
    this.setDataServices();
  }

  public generateStatusWidget(dataSource : any) : void {
    let data : StatusWidget[] = [
      {
        title : 'Servicios Ejecución',
        iconName : 'play_circle',
        value : 500
      },
      {
        title : 'Servicios Pausados',
        iconName : 'stop_circle',
        value : 123
      },
      {
        title : 'Servicios Cancelados',
        iconName : 'cancel',
        value : 10
      },
      {
        title : 'Servicios Finalizados',
        iconName : 'task_alt',
        value : 123
      }
    ]; 
    this.dataSouceWidget = data;
  }

  private setDataUser() : void{
    this.dataUsers.titles = ['nombres', 'correo', 'roll', 'estado'];
    this.dataUsers.dataSource = ELEMENT_DATA
  }

  private setDataServices(): void{
    this.dataServices.titles = ['solicitante', 'prestador', 'direccion', 'estado'];
    this.dataServices.dataSource = ELEMENT_DATA_SERVICE
  }

}
