<mat-card class="content-card">
    <form [formGroup]="companyFormGroup" (ngSubmit)="ValidationSaveCompany()">
        <mat-card-header class="content-titles">
            <mat-card-title class="title">Ingresar Usuarios</mat-card-title>
            <mat-card-subtitle class="subtitles">Formulario de ingreso para usuario nuevo</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="ng-form-content" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="5px">

            <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                <mat-label>Nombre / Rason social</mat-label>
                <input matInput type="text" formControlName="nameField" required placeholder="Nombre de compañia">
                <mat-icon matSuffix aria-hidden="false" fontIcon="badge"></mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                <mat-label>Nit</mat-label>
                <input matInput type="text" formControlName="nitField" required
                    placeholder="Porfavor ingrese el nit de la compañia">
                <mat-icon matSuffix aria-hidden="false" fontIcon="dialpad"></mat-icon>
            </mat-form-field>



            <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                <mat-label>Correo Electrionico</mat-label>
                <input matInput type="email" formControlName="emailField" required
                    placeholder="Porfavor ingrese el correo de la compañia">
                <mat-icon matSuffix aria-hidden="false" fontIcon="mail"></mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                <mat-label>Numero Telefonico</mat-label>
                <input matInput type="phone" formControlName="phoneField" required
                    placeholder="Porfavor ingrese el numero telefonico de la compañia">
                <mat-icon matSuffix aria-hidden="false" fontIcon="phone"></mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                <mat-label>Dirección</mat-label>
                <input matInput type="text" formControlName="addressField" required
                    placeholder="Porfavor ingrese la direccion de la compañia">
                <mat-icon matSuffix aria-hidden="false" fontIcon="business"></mat-icon>
            </mat-form-field>

        </mat-card-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close (click)="closeModal()">Cancelar</button>
            <button mat-button cdkFocusInitial class="primary-btn" color="primary" type="submit">Guardar</button>
        </mat-dialog-actions>
    </form>
</mat-card>