import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { IResult } from 'src/app/utilities/interfaces/result-interface';
import { UserInterface } from 'src/app/utilities/interfaces/user-interface';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-access-management',
  templateUrl: './access-management.component.html',
  styleUrls: ['./access-management.component.less']
})
export class AccessManagementComponent {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

}
