import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExternalOrdersComponent } from './main/external-orders.component';
import { ServiceOrderComponent } from './service-order/service-order.component';

const routes: Routes = [
  {
    path: 'service-order',
    component: ExternalOrdersComponent,
    children: [
      {
        path: 'new-order/:apikey',
        component : ServiceOrderComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExternalOrdersRoutingModule { }
