import { AfterViewInit, Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CompanieService } from 'src/app/services/companie.service';
import { ProfessionalTypeService } from 'src/app/services/profesional-type.service';
import { ServicesService } from 'src/app/services/services.service';
import { ApplicantUserEntitie, ServiceOrderEntitie } from 'src/app/utilities/entities/service-order.entitie';
import { AlertsHelper } from 'src/app/utilities/helpers/alerts-helper';
import { DateHelper } from 'src/app/utilities/helpers/date-helper';
import { ApiKeyInterface } from 'src/app/utilities/interfaces/apikey.interface';
import { CompanyInterface } from 'src/app/utilities/interfaces/company-interface';
import { professionalTypeInterface } from 'src/app/utilities/interfaces/professional-type-Interface';
import { IResult } from 'src/app/utilities/interfaces/result-interface';
import { ServiceTypeInterface } from 'src/app/utilities/interfaces/servicetype-interface';
import { StatusInterface } from 'src/app/utilities/interfaces/status-interface';

@Component({
  selector: 'app-service-order',
  templateUrl: './service-order.component.html',
  styleUrls: ['./service-order.component.less']
})
export class ServiceOrderComponent  implements AfterViewInit{
  public companyData !: CompanyInterface;
  private apikey : string | null = null;
  private idCompany : string | undefined;
  
  public showAditionalInfo: boolean = false;
  public typeService: ServiceTypeInterface[] = [];
  public typeProfessional : professionalTypeInterface[] = [];  
  
  constructor(
    private route: ActivatedRoute,
    private companieService: CompanieService,
    private alertsHelper :  AlertsHelper,
    private router: Router,
    private authService : AuthService,
    private servicesService: ServicesService,
  ) {
  }
  
  ngAfterViewInit() {
    this.apikey = this.route.snapshot.paramMap.get('apikey');
    this.validationApiKeyCompanie();
  }

  private validationApiKeyCompanie(){
    this.authService.ValidateApiKey({apiKey : this.apikey}).subscribe({
      next : (res : IResult<ApiKeyInterface>) => {
        debugger;
         if(!res.isSuccess){
           this.router.navigate(['/access/sing-in']);
         }
         this.idCompany = res.data.idCompany;
         localStorage.setItem('token', res.data.apiToken);
         this.getInformationCompanie();
      },
      error: (e) => console.error(
        this.alertsHelper.error(e)
      ),

    });
  
  }

  private getInformationCompanie(): void {
    if (this.idCompany) {
      this.companieService.GetCompanyById({ companyId: this.idCompany }).subscribe({
        next: (res: CompanyInterface) => {
          this.companyData = res;
        },
        error: (e) => console.error(
          this.alertsHelper.error(e)
        ),
      });
    } else {
      this.router.navigate(['/access/sing-in']);
    }
  }

  public ValidationServiceOrder(formData : any) {
    console.log(formData);
    this.servicesService.GenerateServiceOrder(formData).subscribe({
      next: (res: ServiceOrderEntitie) => {
        this.alertsHelper.success(`Su orden de servicio ha sido generada satisfactoriamente. Pronto nos pondremos en contacto con usted.`);
      },
      error: (e) => console.error(
        this.alertsHelper.error(e)
      ),
    });
    // let form = this.serviceOrderFormGroup;
    // if (form.valid) {
    //   let serviceOrderForm: ServiceOrderEntitie = {
    //     id: '',
    //     createdUserId: undefined,
    //     createdCompanyId: this.companyInfo?.id,
    //     description: form.value.serviceDescriptionField,
    //     applicantUser: {
    //       applicantName: form.value.applicantUserNameField,
    //       applicantNameCompany: form.value.applicantUserCompanyNameField,
    //       applicantPhone: form.value.applicantUserPhoneField,
    //       applicantEmail: form.value.applicantUserEmailField
    //     },
    //     applicantCompany: {
    //       id: '',
    //       name: form.value.applicantCompanyNameField,
    //       nit: form.value.applicantCompanyNitField,
    //       address: form.value.applicantCompanyAdressField,
    //       phone: form.value.applicantCompanyCityField,
    //       status: undefined,
    //       parentId: undefined
    //     },
    //     providingCompany: this.companyInfo,
    //     serviceType: form.value.serviceTypeField,
    //     professionalType: form.value.serviceTypeProfesionField,
    //     assignedUser: undefined,
    //     dateStart: this.dateHelper.formatDateBasic(form.value.serviceStartDateField),
    //     dateEnd: this.dateHelper.formatDateBasic(form.value.serviceEndDateField),
    //     aditionalInformation: (this.showAditionalInfo) ? {
    //       toolsDescription: form.value.serviceToolsDescriptionField,
    //       equipmentDescription: form.value.serviceMachineryDescriptionField,
    //       machineryDescription: form.value.serviceTequipmentDescriptionField,
    //     } : undefined,
    //     createdDate: this.dateHelper.formatDate(new Date()),
    //     updatedDate: undefined,
    //     coordinatorName: form.value.coordinatorNameField,
    //     coordinatorPhone: form.value.coordinatorPhoneField,
    //     numberWorkers: form.value.serviceNumbWorkersField,
    //     serviceRiskLevel: form.value.serviceRiskLevelField,
    //     emailNotification: 'morat26@gmail.com',
    //     serviceStatus: this.serviceStatusDefault,
    //     serviceApplicationSchedule: form.value.serviceApplicationScheduleField,
    //     sincronizationDate: undefined
    //   }

    //   this.servicesService.GenerateServiceOrder(serviceOrderForm).subscribe({
    //     next: (res: ServiceOrderEntitie) => {
    //       this.alertsHelper.success(`Su orden de servicio ha sido generada satisfactoriamente. Pronto nos pondremos en contacto con usted.`);
    //     },
    //     error: (e) => console.error(
    //       this.alertsHelper.error(e)
    //     ),
    //   });
    //}
  }
}
