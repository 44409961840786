import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { UserEntitie } from 'src/app/utilities/entities/user.entitie';
import { AlertsHelper } from 'src/app/utilities/helpers/alerts-helper';

@Component({
  selector: 'app-user-disabled',
  templateUrl: './user-disabled.component.html',
  styleUrls: ['./user-disabled.component.less']
})
export class UserDisabledComponent {

  constructor(
    private userService : UserService,
    private dialog: MatDialog,
    private alertsHelper :  AlertsHelper,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any | null
  ) {

  }

  public ConfirmationUserDesabled(){
    debugger;
    let user : UserEntitie = this.data;
    user.status = {
      id: "31553953-f6da-4f5a-a144-dd67d3822119",
      name: "Inactivo",
      description: "General disable state"
    }
     console.log(user),
    this.userService.UpdateUser(user).subscribe({
      next: (res :UserEntitie ) => {
        this.alertsHelper.success(this.translate.instant('users_module.success_insert', {name: 'success_insert'}));
        //this.dialog.closeAll();
      },
      error: (e) => console.error(
        this.alertsHelper.error(e)
      ),
    });
  }
  
  public closeModal(){
    this.dialog.closeAll();
  }

}
