<div class="content-dashboard">
    <div class="title-module">
        <span>Administrador de Empresas</span>
    </div>
    <div class="boddy-module" fxLayout="column">
        <div class="status-widgets">
            <app-card-status [dataSource]="dataSouceWidget"> </app-card-status>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
            <div class="card-list" fxFlex="100">
                <mat-card class="example-card">
                    <mat-card-header class="title-modules" fxLayout="row">
                        <div class="title" fxFlex="30">
                            <mat-card-title>Listado de Empresas</mat-card-title>
                        </div>
                        <div class="actions" fxLayout="row" fxLayoutAlign="end center" fxFlex="80">
                            <mat-form-field class="filter-input" appearance="outline">
                                <mat-label>Filtro</mat-label>
                                <input matInput placeholder="Ingrese un nombre" (keyup)="applyFilter($event)">
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>
                            <div class="add-action" fxLayoutAlign="center center">
                                <button mat-button class="add-btn" fxLayout="row" fxLayoutAlign="center center"
                                    (click)="openManagerCompanies()">
                                    <mat-icon>add_circle</mat-icon>
                                    <span>Nuevo</span>
                                </button>
                            </div>
                        </div>
                    </mat-card-header>
                    <mat-card-content>

                        <table mat-table [dataSource]="companyDataSource">
                            <ng-container matColumnDef="Nombre">
                                <th mat-header-cell *matHeaderCellDef> Nombre </th>
                                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Nit">
                                <th mat-header-cell *matHeaderCellDef> Nit </th>
                                <td mat-cell *matCellDef="let element"> {{element.nit}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Direccion">
                                <th mat-header-cell *matHeaderCellDef> Direccion </th>
                                <td mat-cell *matCellDef="let element"> {{element.address}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Telefono">
                                <th mat-header-cell *matHeaderCellDef> Telefono </th>
                                <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
                            </ng-container>

                            <ng-container matColumnDef="Estado">
                                <th mat-header-cell *matHeaderCellDef> Estado </th>
                                <td mat-cell *matCellDef="let element"> {{element.status.name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="Actions">
                                <th mat-header-cell *matHeaderCellDef> Acciones </th>
                                <td mat-cell *matCellDef="let element">
                                    <button mat-icon-button [matMenuTriggerFor]="menu"
                                    aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="openCompanieEditModal(element)">
                                        <mat-icon>dialpad</mat-icon>
                                        <span>Editar</span>
                                    </button>
                                    <button mat-menu-item (click)="openCompanieDeletedModal(element)">
                                        <mat-icon>delete</mat-icon>
                                        <span>Eliminar</span>
                                    </button>                                  
                                </mat-menu>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="companyDataTitle"></tr>
                            <tr mat-row *matRowDef="let row; columns: companyDataTitle;"></tr>
                        </table>

                        <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons
                            aria-label="Select page of periodic elements">
                        </mat-paginator>

                    </mat-card-content>
                </mat-card>
            </div>

        </div>
    </div>
</div>