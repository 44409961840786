import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CompanieService } from 'src/app/services/companie.service';
import { CompanieEntitie, StatusCompanyEntitie } from 'src/app/utilities/entities/companie.entitie';
import { AlertsHelper } from 'src/app/utilities/helpers/alerts-helper';
import { UserInterface } from 'src/app/utilities/interfaces/user-interface';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-company-manager',
  templateUrl: './company-manager.component.html',
  styleUrls: ['./company-manager.component.less']
})
export class CompanyManagerComponent {

  public companyFormGroup : FormGroup = this.createCompanyFrom();
  private userSection : UserInterface | undefined = this.getInfoUser();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any | null,
    public dialog: MatDialog,
    public companieService : CompanieService,
    private alertsHelper :  AlertsHelper,
    private translate: TranslateService,
  ) {
  }

  async ngOnInit() : Promise<void> {
    this.setCompanyEdit(); 
  }

  private getInfoUser(): UserInterface {
    let user = localStorage.getItem('user');
    console.log(JSON.parse(user!));
    return JSON.parse(user!);
  }

  private setDefaultStatus() : StatusCompanyEntitie {
    return {
      id : "beafe251-df99-4123-8939-af1a37e349dc",
      name : "Activo",
      description : "General active state"
    }
  }

  public createCompanyFrom():FormGroup {
    return this.companyFormGroup = new FormGroup({
      nameField: new FormControl(''),
      nitField: new FormControl(''),
      emailField: new FormControl(''),
      phoneField: new FormControl(''),
      addressField: new FormControl(''),
      statusField: new FormControl(''),
      parent_idField: new FormControl(''),
    });
  } 

  public setCompanyEdit(){
    if(this.data != null){
      this.companyFormGroup.get('nameField')?.setValue(this.data.name);
      this.companyFormGroup.get('nitField')?.setValue(this.data.nit);
      this.companyFormGroup.get('emailField')?.setValue(this.data.email);
      this.companyFormGroup.get('phoneField')?.setValue(this.data.phone);
      this.companyFormGroup.get('addressField')?.setValue(this.data.address);
      this.companyFormGroup.get('statusField')?.setValue(this.data.status);
      this.companyFormGroup.get('parent_idField')?.setValue(this.data.parent_id);    
    }
  }


  public ValidationSaveCompany() : void {
    let form = this.companyFormGroup;
    debugger;
    if(form.valid){  
      let companyForm : CompanieEntitie = new CompanieEntitie();
      companyForm.id = (this.data?.id) ? this.data.id : uuidv4();
      companyForm.name = form.value.nameField; 
      companyForm.nit = form.value.nitField;
      companyForm.phone = form.value.phoneField;
      companyForm.email = form.value.emailField;
      companyForm.address = form.value.addressField;
      companyForm.status = (this.data?.status == null) ? this.setDefaultStatus() : this.data.status;
      companyForm.parent_id = this.userSection?.company.id

      if(this.data?.id != null){
        this.updatedCompany(companyForm);
      }else{
        this.addNewCompany(companyForm);
      }
    }
  }

  private updatedCompany(companyForm : CompanieEntitie){
    this.companieService.UpdateCompanie(companyForm).subscribe({
      next: (res :CompanieEntitie ) => {
        this.dialog.closeAll();
        this.alertsHelper.success(this.translate.instant('users_module.success_insert', {name: 'success_insert'}));
      },
      error: (e) => console.error(
        this.alertsHelper.error(e)
      ),
    });
  }

  private addNewCompany(companyForm : CompanieEntitie){
    this.companieService.InsertCompanie(companyForm).subscribe({
      next: (res :CompanieEntitie ) => {
        this.dialog.closeAll();
        this.alertsHelper.success(this.translate.instant('users_module.success_insert', {name: 'success_insert'}));
      },
      error: (e) => console.error(
        this.alertsHelper.error(e)
      ),
    });
  }

  public closeModal(){
    this.dialog.closeAll();
  }
}
