import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessManagementRoutingModule } from './access-management-routing.module';
import { AccessManagementComponent } from './access-management.component';
import { MaterialModule } from 'src/app/utilities/helpers/material-module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { SingInComponent } from './sing-in/sing-in.component';
import { SingUpComponent } from './sing-up/sing-up.component';

@NgModule({
  declarations: [
    AccessManagementComponent,
    SingInComponent,
    SingUpComponent
  ],
  imports: [
    MaterialModule,
    FlexLayoutModule,
    AccessManagementRoutingModule,
  ]
})
export class AccessManagementModule { }
