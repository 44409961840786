import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CompanieService } from 'src/app/services/companie.service';
import { ProfessionalTypeService } from 'src/app/services/profesional-type.service';
import { ServicesService } from 'src/app/services/services.service';
import { ServiceOrderEntitie } from 'src/app/utilities/entities/service-order.entitie';
import { AlertsHelper } from 'src/app/utilities/helpers/alerts-helper';
import { CompanyInterface } from 'src/app/utilities/interfaces/company-interface';
import { professionalTypeInterface } from 'src/app/utilities/interfaces/professional-type-Interface';
import { ServiceTypeInterface } from 'src/app/utilities/interfaces/servicetype-interface';
import { UserInterface } from 'src/app/utilities/interfaces/user-interface';

@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.less']
})
export class CreateOrderComponent implements OnInit {
  public companyData: CompanyInterface | undefined;
  public userData: UserInterface;
  public typeService: ServiceTypeInterface[] = [];
  public typeProfessional: professionalTypeInterface[] = [];

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private companieService: CompanieService,
    private servicesService: ServicesService,
    private professionalTypeService: ProfessionalTypeService,
    private alertsHelper: AlertsHelper,
  ) {
    this.userData = this.getInfoUser();
  }

  ngOnInit(): void {

  }

  private getInfoUser(): UserInterface {
    let user = localStorage.getItem('user');
    return JSON.parse(user!);
  }

  private setInfoDataform(): void {

  }

  public closeModal() {
    this.dialog.closeAll();
  }

  public ValidationServiceOrder(formData: any): void {
    console.log('datos de formulario', formData);
    this.servicesService.GenerateServiceOrder(formData).subscribe({
      next: (res: ServiceOrderEntitie) => {
        this.alertsHelper.success(`Su orden de servicio ha sido generada satisfactoriamente. Pronto nos pondremos en contacto con usted.`);
      },
      error: (e) => console.error(
        this.alertsHelper.error(e)
      ),
    });
  }
}
