import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CompanieService } from 'src/app/services/companie.service';
import { CompanieEntitie } from 'src/app/utilities/entities/companie.entitie';
import { AlertsHelper } from 'src/app/utilities/helpers/alerts-helper';

@Component({
  selector: 'app-company-deleted',
  templateUrl: './company-deleted.component.html',
  styleUrls: ['./company-deleted.component.less']
})
export class CompanyDeletedComponent {
  /**
   *
   */
  constructor(
    public companieService: CompanieService,
    private dialog: MatDialog,
    private alertsHelper: AlertsHelper,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any | null
  ) {

  }

  public confirmationDeleteCompany(): void {
    debugger;
    this.companieService.DeleteCompanie(this.data.id).subscribe({
      next: (res: CompanieEntitie) => {
        this.dialog.closeAll();
        this.alertsHelper.success(this.translate.instant('users_module.success_delete', { name: 'success_delete' }));
      },
      error: (e) => console.error(
        this.alertsHelper.error(e)
      ),
    });
  }

  public closeModal() {
    this.dialog.closeAll();
  }

}
