import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { UserEntitie } from 'src/app/utilities/entities/user.entitie';
import { AlertsHelper } from 'src/app/utilities/helpers/alerts-helper';

@Component({
  selector: 'app-user-deleted',
  templateUrl: './user-deleted.component.html',
  styleUrls: ['./user-deleted.component.less']
})
export class UserDeletedComponent {
  constructor(
    private userService : UserService,
    private dialog: MatDialog,
    private alertsHelper :  AlertsHelper,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any | null
  ) { }

  public confirmationDeleteUser () : void {
    debugger;
    this.userService.DeleteUser(this.data.id).subscribe({
      next: (res : UserEntitie ) => {
        this.dialog.closeAll();
        this.alertsHelper.success(this.translate.instant('users_module.success_delete', {name: 'success_delete'}));
      },
      error: (e) => console.error(
        this.alertsHelper.error(e)
      ),
    });
  }

  public closeModal(){
    this.dialog.closeAll();
  }
}
