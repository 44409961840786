 import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-sing-up',
  templateUrl: './sing-up.component.html',
  styleUrls: ['./sing-up.component.less']
})

export class SingUpComponent {

  public singUpForm : FormGroup = this.createFormSingUp();
  
  constructor() {
 
  }  

  private createFormSingUp() : FormGroup {
    return this.singUpForm = new FormGroup({
      nameField : new FormControl(''),
      lastNameField : new FormControl(''),
      emailField : new FormControl(''),
      phoneField : new FormControl(''),
    });
  }

  public validationSingupForm(){
    let form = this.singUpForm;
    console.log(form);
  } 

  public openSingUpByGmail(){
    console.log('Inicio de registro con Gmail');
  }

  public openSingUpByFacebook(){
    console.log('Inicio de registro con Facebook');
  }


}
