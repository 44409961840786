import { Component, Input } from '@angular/core';
import { StatusWidget } from 'src/app/utilities/interfaces/status-widget';

@Component({
  selector: 'app-card-status',
  templateUrl: './card-status.component.html',
  styleUrls: ['./card-status.component.less']
})


export class CardStatusComponent {
  @Input() dataSource : StatusWidget[] = [];

  constructor() {
    
  }
}
