import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserEntitie } from '../utilities/entities/user.entitie';
import { IResult } from '../utilities/interfaces/result-interface';
import { UserInterface } from '../utilities/interfaces/user-interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient
  ) { }

  public GetUserByCompany(params : any ) : Observable<UserInterface[]>{
    let uri = environment.management_api + 'user/UsersByIdCompany';
    return  this.http.get<UserInterface[]>(uri, {params: params});
  }

  public GetcollaboratorUsersByIdCompany(params : any ) : Observable<UserInterface[]>{
    let uri = environment.management_api + 'user/UsersCollabolatorByIdCompany';
    return  this.http.get<UserInterface[]>(uri, {params: params});
  }

  public InsertUser(params : UserEntitie) : Observable<UserEntitie>{
    let uri = environment.management_api + 'user/InsertUser';
    return  this.http.post<UserEntitie>(uri, params);
  }

  public UpdateUser(params : UserEntitie) : Observable<UserEntitie>{
    let uri = environment.management_api + 'user/UpdateUser';
    return  this.http.put<UserEntitie>(uri, params);
  }

  public DeleteUser(idUser : string) : Observable<UserEntitie>{
    let uri = environment.management_api + 'user/DeleteUser?userService=' + idUser;
    return  this.http.delete<UserEntitie>(uri);
  }

}
