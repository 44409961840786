

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, finalize, throwError } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private loadingService: LoaderService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token: string | null = localStorage.getItem('token');
    let request = req;
    if (token) {
      request = req.clone({
        setHeaders: { authorization: `Bearer ${ token.replace(/"/g, '')}` }
      });
    } 

    this.loadingService.setLoading(true); 
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          localStorage.clear();
          this.router.navigate(['/access/sing-in']);
        }
        return throwError(() => new Error(err.message));
      }),
      finalize(() => { this.loadingService.setLoading(false); })
    );
  }

}