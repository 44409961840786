<div class="content-status" fxLayout="row" fxLayoutAlign="space-evenly center" >

    <mat-card class="section-card" fxLayout="column" *ngFor="let item of dataSource;">
        <div class="cnt-body-status"  fxLayout="row" >
            <div class="icon-card" fxLayoutAlign="center center" fxFlex="30">
                <mat-icon>{{item.iconName}}</mat-icon>
            </div> 
            <div class="cnt-body" fxLayout="column" fxLayoutAlign="start end" fxFlex="80">
                <span class="title"> {{item.title}}</span>
                <span class="sub-title"> {{item.value}}</span>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="footer-status" fxLayout="row">
            <mat-icon>update</mat-icon>
            <span>Ultimos 30 dias</span>
        </div>
    </mat-card>
</div>