import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less']
})

export class SidebarComponent implements OnInit {
  
  @ViewChild(MatMenuTrigger) menu!: MatMenuTrigger;
  
  constructor() { }

  ngOnInit(): void {

  }

}


