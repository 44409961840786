import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServiceOrderInterface } from 'src/app/utilities/interfaces/service-orders.interface';
import { TableDataSource } from 'src/app/utilities/interfaces/table-data-source';
import { StatusWidget } from 'src/app/utilities/interfaces/status-widget';
import { UserInterface } from 'src/app/utilities/interfaces/user-interface';
import { ServicesService } from 'src/app/services/services.service';
import { StatusServiceEnum } from 'src/app/utilities/enum/status-service-enum';
import { CreateOrderComponent } from '../modals/create-order/create-order.component';
import { OrderDetailsComponent } from '../modals/order-details/order-details.component';

@Component({
  selector: 'app-order-services',
  templateUrl: './order-services.component.html',
  styleUrls: ['./order-services.component.less']
})
export class OrderServicesComponent implements OnInit {

  private userData: UserInterface;
  public dataSouceWidget: StatusWidget[] = [];
  public servicesDataSource: any | undefined;
  public servicesDataTitle: string[] = [];
  public dataServices: TableDataSource<ServiceOrderInterface[]> = {
    titles: [],
    dataSource: []
  };

  constructor(
    public dialog: MatDialog,
    public services: ServicesService
  ) {
    this.userData = this.getInfoUser();
  }

  ngOnInit(): void {
    // this.getServicesWidget();
    this.getServicesUser();
  }

  private getServicesUser() {
    console.log(this.userData);
    let params: object = { companyId: this.userData.company.id };
    this.services.getServicesByIdCompany(params).subscribe({
      next: (res: any) => {
        this.setDataWidget(res);
        this.setDataServices(res);
      },
      error: (error: any) => {
        console.error(error);
      },
    })

  }

  private getInfoUser(): UserInterface {
    let user = localStorage.getItem('user');
    return JSON.parse(user!);
  }

  private setDataWidget(res: any): void {
    let dataWidget: StatusWidget[] = [
      {
        title: 'En Ejecución',
        iconName: 'play_circle',
        value: res.filter((service: any) => service.serviceStatus.id == StatusServiceEnum.Ejecucion).length
      },
      {
        title: 'Asignados',
        iconName: 'task_alt ',
        value: res.filter((service: any) => service.serviceStatus.id == StatusServiceEnum.Asignados).length
      },
      {
        title: 'No Asignados',
        iconName: 'stop_circle',
        value: res.filter((service: any) => service.serviceStatus.id == StatusServiceEnum.NoAsignados).length
      },
      {
        title: 'Servicios Finalizados',
        iconName: 'cancel',
        value: res.filter((service: any) => service.serviceStatus.id == StatusServiceEnum.Finalizados).length
      }
    ];
    this.dataSouceWidget = dataWidget;
  }

  private setDataServices(res: any): void {
    console.log(res);
    this.servicesDataTitle = ['Solicitante', 'Compañia', 'Dirección', 'Telefono', 'Tipo', 'Asignado', 'Estado', 'Creado', 'Fecha de Inicio', 'Fecha de Final'];
    this.servicesDataSource = res;
  }

  public openManagerService(): void {
    const dialogRef = this.dialog.open(CreateOrderComponent);
  }

  public applyFilter(event: any): void {

  }
  public exportReport(): void {

  }

  public openDetailServiceOrder(element: any): void {
    const dialogRef = this.dialog.open(OrderDetailsComponent, {
      width: '800px',
      autoFocus: false,
      maxHeight: '80vh',
      data: element
    });
    console.log('Row clicked: ', element);
  }

  public validationStatus(serviceStatus: string): string {
    switch (serviceStatus) {
      case StatusServiceEnum.Ejecucion:
        return 'status-in-progress';
      case StatusServiceEnum.Asignados:
        return 'status-assigned';
      case StatusServiceEnum.Finalizados:
        return 'status-success';
      case StatusServiceEnum.NoAsignados:
        return 'status-disabled';
      case StatusServiceEnum.Cancelado:
        return 'status-alert';
      default:
        return 'status-dusabled';
    }
  }
}
