import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminsRoutingModule } from './admins-routing.module';
import { UserAdminComponent } from './user-admin/user-admin.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ManagerUserComponent } from './user-admin/modals/manager-user/manager-user.component';
import { MaterialModule } from 'src/app/utilities/helpers/material-module';
import { MainModule } from '../main/main.module';
import { MainComponent } from './main/main.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UserDeletedComponent } from './user-admin/modals/user-deleted/user-deleted.component';
import { CompaniesAdminComponent } from './companies-admin/main/companies-admin.component';
import { UserDisabledComponent } from './user-admin/modals/user-disabled/user-disabled.component';
import { CompanyDeletedComponent } from './companies-admin/modals/company-deleted/company-deleted.component';
import { CompanyManagerComponent } from './companies-admin/modals/company-manager/company-manager.component';


@NgModule({
  declarations: [
    ManagerUserComponent,
    UserAdminComponent,
    MainComponent,
    UserDeletedComponent,
    CompaniesAdminComponent,
    UserDisabledComponent,
    CompanyDeletedComponent,
    CompanyManagerComponent
  ], 
  imports: [
    AdminsRoutingModule,
    MaterialModule,
    MainModule,
    FlexLayoutModule,
    CommonModule
  ],

})
export class AdminsModule { }
