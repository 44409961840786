
<div class="content-layout" fxLayout="row">   
    <div class="content-menu" fxFlex="20">
        <app-sidebar></app-sidebar>
    </div>
    <div class="content-body" fxFlex="80">
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
        <div class="footer-layout">
            
        </div>
    </div>
</div>