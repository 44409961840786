import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExternalOrdersRoutingModule } from './external-orders-routing.module';
import { ExternalOrdersComponent } from './main/external-orders.component';
import { ServiceOrderComponent } from './service-order/service-order.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/utilities/helpers/material-module';
import { ServiceOrderFormComponent } from 'src/app/components/forms/service-order-form/service-order-form.component';
import { MainModule } from '../main/main.module';


@NgModule({
  declarations: [
    ExternalOrdersComponent,
    ServiceOrderComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    MainModule,
    ExternalOrdersRoutingModule
  ]
})
export class ExternalOrdersModule { }
