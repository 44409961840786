import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { RollService } from 'src/app/services/roll.service';
import { StatusService } from 'src/app/services/status.service';
import { UserService } from 'src/app/services/user.service';
import { UserEntitie } from 'src/app/utilities/entities/user.entitie';
import { AlertsHelper } from 'src/app/utilities/helpers/alerts-helper';
import { RolInterface } from 'src/app/utilities/interfaces/rol-interface';
import { StatusInterface } from 'src/app/utilities/interfaces/status-interface';
import { UserInterface } from 'src/app/utilities/interfaces/user-interface';
import { v4 as uuidv4 } from 'uuid';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { from } from 'rxjs';

@Component({
  selector: 'app-manager-user',
  templateUrl: './manager-user.component.html',
  styleUrls: ['./manager-user.component.less']
})
export class ManagerUserComponent  implements OnInit{
  public userFormGroup : FormGroup = this.createInsertFrom();
  public rolls : RolInterface[] = [];
  public userStatus : StatusInterface[] = [];
  private sesionUsser : UserInterface | undefined; 

  constructor(
    private rollService : RollService,
    private statusService : StatusService,
    public dialog: MatDialog,
    private userService : UserService,
    private translate: TranslateService,
    private alertsHelper :  AlertsHelper,
    @Inject(MAT_DIALOG_DATA) public data: any | null

  ) {
  
  }
  async ngOnInit() : Promise<void> {
    await this.searchAllRols();
    await this.searchAllStatusUsers();
    this.getUserSection();
    
  }

  public setUserEdit(){
    if(this.data != null){
      this.userFormGroup.get('nameField')?.setValue(this.data.name);
      this.userFormGroup.get('lastNameField')?.setValue(this.data.lastName);
      this.userFormGroup.get('emailField')?.setValue(this.data.email);
      this.userFormGroup.get('statusField')?.setValue(this.data.status);
      this.userFormGroup.get('passwordField')?.setValue(this.data.password);
      this.userFormGroup.get('identificationField')?.setValue(this.data.numIdentification);
      this.userFormGroup.get('confirmationPasswordField')?.setValue(this.data.password);
      this.userFormGroup.get('rolField')?.setValue(this.rolls.filter(x => x.id == this.data.rol.id)[0]);
      this.userFormGroup.get('phoneField')?.setValue(this.data.phone);      
    }
  }

  public getUserSection(){
    let user = localStorage.getItem('user');
    this.sesionUsser = JSON.parse((user != null) ?user : "");
  }

  public async searchAllRols() : Promise<void>{
    this.rollService.GetRolls().subscribe(
      (res : RolInterface[]) => {
        console.log(res);
        this.rolls = res;
        this.setUserEdit();
      } 
    )
  }
  
  public async  searchAllStatusUsers() : Promise<void>{
    this.statusService.GetAllStatus().subscribe(
      (res : StatusInterface[]) => {
        this.userStatus = res;
      } 
    )
  }

  public createInsertFrom():FormGroup {
    return this.userFormGroup = new FormGroup({
      nameField: new FormControl(''),
      lastNameField: new FormControl(''),
      emailField: new FormControl(''),
      identificationField: new FormControl(''),
      passwordField: new FormControl(''),
      confirmationPasswordField: new FormControl(''),
      rolField: new FormControl(''),
      statusField : new FormControl(''),
      phoneField : new FormControl(''),
    });
  } 

  public closeModal(){
    this.dialog.closeAll();
  }

  public ValidationSaveUser() : void {
    let form = this.userFormGroup;
    debugger;
    if(form.valid){  
      let userForm : UserEntitie = new UserEntitie();
      userForm.id = (this.data?.id) ? this.data.id : uuidv4();
      userForm.status = this.userStatus[0];
      userForm.rol = form.value.rolField; 
      userForm.company = this.sesionUsser!.company;
      userForm.idSuscription = this.sesionUsser!.suscription.id;
      userForm.name =form.value.nameField; 
      userForm.lastName =form.value.lastNameField;
      userForm.email =form.value.emailField;
      userForm.password = form.value.passwordField;
      userForm.numIdentification = form.value.identificationField;
      userForm.phone = String(form.value.phoneField);
      if(this.data?.id != null){
        this.updatedUser(userForm);
      }else{
        this.addNewUser(userForm);
      }
    }
  }

  private updatedUser(user : UserEntitie) : void{
    this.userService.UpdateUser(user).subscribe({
      next: (res :UserEntitie ) => {
        this.dialog.closeAll();
        this.alertsHelper.success(this.translate.instant('users_module.success_insert', {name: 'success_insert'}));
      },
      error: (e) => console.error(
        this.alertsHelper.error(e)
      ),
    });
  }

  private addNewUser(user :UserEntitie): void {
    this.userService.InsertUser(user).subscribe({
      next: (res :UserEntitie ) => {
        this.dialog.closeAll();
        this.alertsHelper.success(this.translate.instant('users_module.success_insert', {name: 'success_insert'}));
      },
      error: (e) => console.error(
        this.alertsHelper.error(e)
      ),
    });
  }

}
