import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { IResult } from 'src/app/utilities/interfaces/result-interface';
import { StatusWidget } from 'src/app/utilities/interfaces/status-widget';
import { TableDataSource } from 'src/app/utilities/interfaces/table-data-source';
import { UserInterface } from 'src/app/utilities/interfaces/user-interface';

import { MatTableDataSource, MatTableDataSourcePaginator } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ManagerUserComponent } from './modals/manager-user/manager-user.component';
import { UserDeletedComponent } from './modals/user-deleted/user-deleted.component';
import { UserDisabledComponent } from './modals/user-disabled/user-disabled.component';


@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.less']
})

export class UserAdminComponent implements OnInit {
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  private userData : UserInterface = this.getInfoUser(); 
  public usersDataSource: any | undefined ;
  public usersDataTitle : string[] = [];
  public dataSouceWidget : StatusWidget[] = [];

  constructor(
    private userService : UserService,
    public dialog: MatDialog
  ) {}
  
  async ngAfterViewInit() {
    this.getUserByIdCompany();
  }

  ngOnInit(): void {
   
  }

  private getInfoUser(): UserInterface {
    let user = localStorage.getItem('user');
    console.log(JSON.parse(user!));
    return JSON.parse(user!);
  }

  public generateStatusWidget(dataSource : UserInterface[]) : void {
    let data : StatusWidget[] = [
      {
        title : 'Total Usuarios',
        iconName : 'verified_user',
        value : dataSource.length
      },
      {
        title : 'Usuarios Activos',
        iconName : 'how_to_reg',
        value : dataSource.filter(x => x.status.name == "active").length
      },
      {
        title : 'Usuarios Inactivos',
        iconName : 'no_accounts',
        value : dataSource.filter(x => x.status.name == "disable").length
      }
    ]; 
    this.dataSouceWidget = data;
  }

  public getUserByIdCompany(): void {
    let idCompany = this.userData.company.id;
    this.userService.GetUserByCompany({idCompany : idCompany}).subscribe(
      (res : UserInterface[]) => {
        this.generateStatusWidget(res);
        this.usersDataSource = new MatTableDataSource(res);
        this.usersDataSource.paginator = this.paginator;
        this.usersDataTitle = ['nombres', 'correo','documento', 'roll', 'estado','acciones']
      }
    );
  }

  public openManagerUser() {
    const dialogRef = this.dialog.open(ManagerUserComponent);
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.usersDataSource.filter = filterValue.trim().toLowerCase();
  }

  public openUserEditModal(element: any) : void{
    console.log(element);
    const dialogRef = this.dialog.open(ManagerUserComponent, {
      data: element
    });
  }

  public openUserDeletedModal(element: any): void{
    const dialogRef = this.dialog.open(UserDeletedComponent, {
      data: element
    });
  }

  public openDisableUserModal(element: any): void{
    const dialogRef = this.dialog.open(UserDisabledComponent, {
      data: element
    });
  }

}
