import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { professionalTypeInterface } from '../utilities/interfaces/professional-type-Interface';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalTypeService {

  constructor(
    private http: HttpClient
  ) { }

  public GetProfessionalTypes(params : any) : Observable<professionalTypeInterface[]>{
    let uri = environment.management_api + 'ProfessionalTypes/GetProfessionalTypesByIdCompany';
    return  this.http.get<professionalTypeInterface[]>(uri, {params : params});
  }
}