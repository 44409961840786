<mat-toolbar class="navbar">
    <!-- <button mat-icon-button class="example-icon"  aria-label="Example icon-button with menu icon">
        <mat-icon>menu</mat-icon>
    </button>
    <span>My App</span> -->
    <span class="example-spacer"></span>

    <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
        <mat-icon matBadge="1" matBadgeColor="warn">notifications</mat-icon>
    </button>
    <div class="content-user">
        <button mat-icon-button id="btn-user-actions" class="btn-user" [matMenuTriggerFor]="userMenu" >
            <img class="img-user"  src="./assets/imgs/fondo2.jpg" alt="">
        </button>
        <mat-menu #userMenu="matMenu">
            <div class="info-user" fxLayout="column" fxLayoutAlign="center center" >
                <div class="info-usert-title" fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
                    <div fxFlex="30" fxLayoutAlign="center center">
                        <img class="img-info-user"  src="../../../assets/imgs/fondo2.jpg" alt="">
                    </div>
                    <div class="status-user" fxLayout="column" fxLayoutAlign="start center" fxFlex="80"> 
                        <span class="title">{{sesionUsser.name}} {{sesionUsser.lastName}} </span>
                        <span class="sub-title">{{sesionUsser.email}}</span>
                    </div>
                </div>
                <div class="body-info-user" fxLayout="column" fxFlex="100">
                    <button id="profile" mat-menu-item (click)="redirectoToProfile()">
                        <mat-icon>manage_accounts</mat-icon>
                        <span>Mi Perfil</span>
                      </button>
                      <button id="logout" mat-menu-item (click)="closeSesion()">
                        <mat-icon>logout</mat-icon>
                        <span>Cerrar Sesión</span>
                      </button>
                </div>
            </div>
        </mat-menu>
    </div>
    
</mat-toolbar>