import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IResult } from '../utilities/interfaces/result-interface';
import { UserInterface } from '../utilities/interfaces/user-interface';
import { ApiKeyInterface } from '../utilities/interfaces/apikey.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient
  ) { }

  public ValidateUser(params : any ) : Observable<IResult<UserInterface>>{
    let uri = environment.auth_api + 'Authentication';
    return  this.http.post<IResult<UserInterface>>(uri, params);
  }

  public ValidateApiKey(params : any ) : Observable<IResult<ApiKeyInterface>>{
    let uri = environment.auth_api + 'Authorization';
    return  this.http.get<IResult<ApiKeyInterface>>(uri, {params : params});
  }
}
