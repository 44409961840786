<form [formGroup]="serviceOrderFormGroup" (ngSubmit)="ValidationServiceOrder()">
    <mat-card-header class="content-titles">
        <mat-card-title class="title">Solicitud de Servicio SST</mat-card-title>
        <mat-card-subtitle class="subtitles">Por favor diligenciar formulario de solicitud, datos concretos y
            puntuales.
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="ng-form-content" fxLayout="column" fxLayoutAlign="center center">
        <section class="company-info" fxLayout="column">
            <div class="title-info">
                <mat-card-subtitle class="subtitles">Informacion empresa solicitante:</mat-card-subtitle>
            </div>
            <div class="content-form" fxLayout="row wrap" fxLayoutGap="5px">
                <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                    <mat-label>Nombres empresa</mat-label>
                    <input matInput type="text" formControlName="applicantUserCompanyNameField" required
                        placeholder="Ingrese el monbre de la compañia">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                    <mat-label>Nombre Solicitante</mat-label>
                    <input matInput type="text" formControlName="applicantUserNameField" required
                        placeholder="Porfavor ingrese sus nombres">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                    <mat-label>Telefono Solicitante</mat-label>
                    <input matInput type="text" formControlName="applicantUserPhoneField" required
                        placeholder="Porfavor ingrese su numero telefonico">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                    <mat-label>Email Solicitante</mat-label>
                    <input matInput type="text" formControlName="applicantUserEmailField" required
                        placeholder="Porfavor ingrese su correo electronico">
                </mat-form-field>
            </div>
        </section>

        <section class="detail-order" fxLayout="column">
            <div class="title-info">
                <mat-card-subtitle class="subtitles">Detalle de orden de servicio:</mat-card-subtitle>
            </div>


            <div class="content-form" fxLayout="row wrap" fxLayoutGap="5px">
                <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="100">
                    <mat-label>Descripción del servicio</mat-label>
                    <textarea formControlName="serviceDescriptionField" matInput></textarea>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                    <mat-label>Tipo de servicio</mat-label>
                    <mat-select formControlName="serviceTypeField" (selectionChange)="validationTypeService()">
                        <mat-option *ngFor="let item of typeService" [value]="item">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                    <mat-label>Tipo de profesional</mat-label>
                    <mat-select formControlName="serviceTypeProfesionField">
                        <mat-option *ngFor="let item of typeProfessional" [value]="item">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                    <mat-label>Nivel de riesgo</mat-label>
                    <input matInput type="text" formControlName="serviceRiskLevelField" required
                        placeholder="Porfavor ingrese sus nombres">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                    <mat-label>Numero de trabajadores</mat-label>
                    <input matInput type="text" formControlName="serviceNumbWorkersField" required
                        placeholder="Porfavor ingrese sus nombres">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                    <mat-label>Fecha de inicio:</mat-label>
                    <input matInput [matDatepicker]="picker" [matDatepickerFilter]="minDate"
                        formControlName="serviceStartDateField">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                    <mat-label>Fecha final:</mat-label>
                    <input matInput [matDatepicker]="picker2" [matDatepickerFilter]="minDateSelect"
                        formControlName="serviceEndDateField">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </div>

        </section>

        <section class="content-aditionals" fxLayout="column" *ngIf="showAditionalInfo">
            <div class="title-info">
                <mat-card-subtitle class="subtitles">Informacion adicional requerida</mat-card-subtitle>
            </div>
            <div class="content-form" fxLayout="column">
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Descripción de herramientas</mat-label>
                    <textarea matInput formControlName="serviceToolsDescriptionField"
                        placeholder="Describa detalladamente las herramientas que se utilizaran en campo ejemplo : taladros, martillos, palas, entre otros"></textarea>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Descripción de maquinarias:</mat-label>
                    <textarea matInput formControlName="serviceMachineryDescriptionField"
                        placeholder="Describa detalladamente la maquinaria que utilizara el personal."></textarea>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Descripción de Equipos utilizados:</mat-label>
                    <textarea matInput formControlName="serviceTequipmentDescriptionField"
                        placeholder="Describa detalladamente las equipos que se implementaran en campo"></textarea>
                </mat-form-field>

            </div>

        </section>

        <section class="detail-order" fxLayout="column">
            <div class="title-info">
                <mat-card-subtitle class="subtitles">Informacion empresa ejecución de servicio:</mat-card-subtitle>
            </div>
            <div class="content-form" fxLayout="row wrap" fxLayoutGap="5px">
                <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                    <mat-label>Nombre de empresa:</mat-label>
                    <input matInput type="text" formControlName="applicantCompanyNameField" required
                        placeholder="Ingrese el monbre de la compañia">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                    <mat-label>Nit empresa:</mat-label>
                    <input matInput type="text" formControlName="applicantCompanyNitField" required
                        placeholder="Porfavor ingrese el nit de la empresa">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                    <mat-label>Dirección empresa:</mat-label>
                    <input matInput type="text" formControlName="applicantCompanyAdressField" required
                        placeholder="Porfavor ingrese la direccion de la compañia:">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                    <mat-label>Ciudad empresa:</mat-label>
                    <input matInput type="text" formControlName="applicantCompanyCityField" required
                        placeholder="Porfavor ingrese sus nombres">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                    <mat-label>Telefono empresa:</mat-label>
                    <input matInput type="text" formControlName="applicantCompanyPhoneField" required
                        placeholder="Porfavor ingrese el numero telefonico de la compañia">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                    <mat-label>Nombre de coordinador:</mat-label>
                    <input matInput type="text" formControlName="coordinatorNameField" required
                        placeholder="Nombre del coordinador en obra:">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                    <mat-label>Telefono de coordinador:</mat-label>
                    <input matInput type="text" formControlName="coordinatorPhoneField" required
                        placeholder="Telefono del coordinador en obra:">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                    <mat-label>Horario:</mat-label>
                    <input matInput type="text" formControlName="serviceApplicationScheduleField" required
                        placeholder="Horario de obra">
                </mat-form-field>
            </div>

        </section>

    </mat-card-content>
    <section class="btn-actions" align="end">
        <button mat-stroked-button  color="secundary" (click)="closeModal()">Cancelar</button>
        <button mat-flat-button cdkFocusInitial class="primary-btn" color="primary" type="submit">{{buttonName}}</button>
    </section>
</form>