<mat-card class="content-card">
    <form >
        <mat-card-header class="content-titles">
            <mat-card-title class="title">Eliminar Usuarios</mat-card-title>
            <mat-card-subtitle class="subtitles">Confirmacion para eliminar usuario.</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="mat-typography">
            <p>Se eliminará el usuario con nombre: <b>{{data.name}} {{this.data.lastName}}</b> </p>
            <p>Una vez realizada esta acción, no se podrá revertir el proceso.</p>
        </mat-card-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close (click)="closeModal()">Cancelar</button>
            <button mat-button cdkFocusInitial class="primary-btn" color="primary" type="submit" (click)="confirmationDeleteUser()">Eliminar</button>
        </mat-dialog-actions>
    </form>
</mat-card>