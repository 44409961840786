<div class="section-login" fxLayout="row">
    <div class="content-img" fxFlex="67"></div>
    <div class="section" fxFlex.gt-xs="100" fxFlex.gt-md="33" fxLayout="column" fxLayoutAlign="center center">

        <router-outlet> </router-outlet>
        
        <div class="footer-login" fxLayout="column" fxLayoutAlign="center center">
            <span>¿Aún no te has registrado? <a href=""> Regístrate aquí</a></span>
        </div>

        <div class="footer" fxLayout="row" fxLayoutAlign="center center">
            <span>Product by: </span> <img class="icon-ui" src="../../../assets/imgs/uicode.png" alt="">
        </div>

    </div>
</div>