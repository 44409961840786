
<div class="content">
    <section class="content-logo">
        <img class="img-logo" src="https://res.cloudinary.com/dnrj7cbj6/image/upload/v1721532551/UiCode/ezicsup5f4ewgbzzdrso.png">
    </section>

    <section class="content-menu">
        <mat-list>
            <span class="title">Principal</span>
            <mat-list-item>
                <button id="Dashboard" class="mat-mdc-menu-item mat-mdc-focus-indicator mdc-list-item" mat-menu-item fxLayoutAlign="start center" [routerLink]="['/main/dashboard']" >
                    <mat-icon>dashboard</mat-icon>
                    <span>Dashboard</span>
                </button>
            </mat-list-item>
        
            <mat-list-item  fxLayout="row" fxLayoutAlign="center center">
                <button id="Servicios" class="mat-mdc-menu-item mat-mdc-focus-indicator mdc-list-item" mat-menu-item fxLayoutAlign="start center" [routerLink]="['/main/services']"  >
                    <mat-icon>list_alt</mat-icon>
                    <span>Servicios</span>
                </button>
            </mat-list-item>
        
            <mat-list-item  fxLayout="row" fxLayoutAlign="center center">
                <button id="Notificaciones" class="mat-mdc-menu-item mat-mdc-focus-indicator mdc-list-item" mat-menu-item fxLayoutAlign="start center" >
                    <mat-icon>notifications_off</mat-icon>
                    <span>Notificaciones</span>
                </button>
            </mat-list-item>
            <!-- <mat-list-item  fxLayout="row" fxLayoutAlign="center center">
                <button id="Reporteria" class="mat-mdc-menu-item mat-mdc-focus-indicator mdc-list-item" mat-menu-item fxLayoutAlign="start center" >
                    <mat-icon>report</mat-icon>
                    <span>Reporteria</span>
                </button>
            </mat-list-item> -->
          
        
        
            <!-- <mat-list-item  fxLayout="row" fxLayoutAlign="center center">
                <button id="Seguimiento" class="mat-mdc-menu-item mat-mdc-focus-indicator mdc-list-item" mat-menu-item fxLayoutAlign="start center" >
                    <mat-icon>person_pin_circle</mat-icon>
                    <span>Seguimiento</span>
                </button>
            </mat-list-item> -->
        
            <mat-divider></mat-divider>
            
            <span class="title">Administrativo</span>
            <mat-list-item  fxLayout="row" fxLayoutAlign="center center">
                <button id="Usuarios" class="mat-mdc-menu-item mat-mdc-focus-indicator mdc-list-item" mat-menu-item fxLayoutAlign="start center" [routerLink]="['/admin/users']">
                    <mat-icon>group</mat-icon>
                    <span>Usuarios</span>
                </button>
            </mat-list-item>
        
            <mat-list-item  fxLayout="row" fxLayoutAlign="center center">
                <button id="Empresas" class="mat-mdc-menu-item mat-mdc-focus-indicator mdc-list-item" mat-menu-item fxLayoutAlign="start center" [routerLink]="['/admin/companies']">
                    <mat-icon>room_preferences</mat-icon>
                    <span> Empresas </span>
                </button>
            </mat-list-item>
        
            <!-- <mat-list-item  fxLayout="row" fxLayoutAlign="center center">
                <button id="BackUps" class="mat-mdc-menu-item mat-mdc-focus-indicator mdc-list-item" mat-menu-item fxLayoutAlign="start center" >
                    <mat-icon>settings_backup_restore</mat-icon>
                    <span> Back Ups</span>
                </button>
            </mat-list-item> -->
        
            <!-- <mat-list-item  fxLayout="row" fxLayoutAlign="center center">
                <button id="Cargas" class="mat-mdc-menu-item mat-mdc-focus-indicator mdc-list-item" mat-menu-item fxLayoutAlign="start center" >
                    <mat-icon>cloud_upload</mat-icon>
                    <span>Cargas</span>
                </button>
            </mat-list-item> -->
        
            <mat-divider></mat-divider>
        </mat-list>
    </section>
</div>


