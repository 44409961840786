import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { IResult } from 'src/app/utilities/interfaces/result-interface';
import { UserInterface } from 'src/app/utilities/interfaces/user-interface';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-sing-in',
  templateUrl: './sing-in.component.html',
  styleUrls: ['./sing-in.component.less']
})
export class SingInComponent implements OnInit {
  public loginFormGroup : FormGroup = this.createForms();

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.createForms();
    this.validationSessionUser()
  }

  private validationSessionUser(){
    let user = localStorage.getItem('user');
    console.log(user);
    if(user){
      this.router.navigate(['/main/dashboard']);
      return;
    }else{
      localStorage.clear();
    }
  }

  private createForms(): FormGroup{
    return this.loginFormGroup = new FormGroup({
      emailField: new FormControl(''),
      passwordField: new FormControl(''),
    });
  }

  public validationUser(): void{
    let form = this.loginFormGroup;
    if(form.valid){
      
      let params = {
        email :form.value.emailField, 
        password : form.value.passwordField
      };
      this.authService.ValidateUser(params).subscribe(
        (res : IResult<UserInterface>) => {
          if(res.isSuccess){
            localStorage.clear();
            localStorage.setItem('token', JSON.stringify(res.data.token));
            localStorage.setItem('user', JSON.stringify(res.data));
            this.router.navigate(['/main/dashboard']);
            return;
          }
          if(!res.isSuccess){
            debugger;
            this.ShowAlertWarning(res.message);
          }
        },
        (error : any) => {
          console.log(error);
          this.ShowAlertWarning('Error al momento de conectar con el servicio');
        }
      );
    }
  }

  private ShowAlertWarning(message: string ){
    Swal.fire({
      title: 'Ups, Algo no salio bien?',
      text: message,
      icon: 'warning',
    });
  }
}
