import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IResult } from '../utilities/interfaces/result-interface';
import { StatusInterface } from '../utilities/interfaces/status-interface';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor(
    private http: HttpClient
  ) { }

  public GetAllStatus() : Observable<StatusInterface[]>{
    let uri = environment.management_api + 'Status/SearchStatus';
    return  this.http.get<StatusInterface[]>(uri);
  }
}
