import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyInterface } from '../utilities/interfaces/company-interface';
import { environment } from 'src/environments/environment';
import { CompanieEntitie } from '../utilities/entities/companie.entitie';

@Injectable({
  providedIn: 'root'
})
export class CompanieService {

  constructor(
    private http: HttpClient
  ) { }

  public GetCompanyById(params : any ) : Observable<CompanyInterface>{
    let uri = environment.management_api + 'Company/GetCompanyById';
    return  this.http.get<CompanyInterface>(uri, {params: params});
  }
  
  public GetCompaniesByParentCompany(params : any ) : Observable<CompanyInterface[]>{
    let uri = environment.management_api + 'Company/GetCompaniesByParentId';
    return  this.http.get<CompanyInterface[]>(uri, {params: params});
  }

  public InsertCompanie(params : CompanieEntitie) : Observable<CompanieEntitie>{
    let uri = environment.management_api + 'Company/InsertCompany';
    return  this.http.post<CompanieEntitie>(uri, params);
  }

  public UpdateCompanie(params : CompanieEntitie) : Observable<CompanieEntitie>{
    let uri = environment.management_api + 'Company/UpdateCompany';
    return  this.http.put<CompanieEntitie>(uri, params);
  }

  public DeleteCompanie(idCompany: string) : Observable<CompanieEntitie>{
    let uri = environment.management_api + 'Company/DeleteCompany?company=' + idCompany;
    return  this.http.delete<CompanieEntitie>(uri);
  }
}
