import { CompanyInterface } from "../interfaces/company-interface";
import { RolInterface } from "../interfaces/rol-interface";
import { StatusInterface } from "../interfaces/status-interface";

export class UserEntitie {
    id : string | undefined;
    status : StatusInterface | undefined; 
    rol : RolInterface | undefined;
    company : CompanyInterface | undefined;
    idSuscription : string | undefined;
    name : string | undefined; 
    lastName : string | undefined;
    email : string | undefined;
    password : string | undefined;
    numIdentification : string | undefined;
    phone : string | undefined;
}