<mat-card class="content-card">
    <form [formGroup]="userFormGroup" (ngSubmit)="ValidationSaveUser()">
        <mat-card-header class="content-titles">
            <mat-card-title class="title">Ingresar Usuarios</mat-card-title>
            <mat-card-subtitle class="subtitles">Formulario de ingreso para usuario nuevo</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="ng-form-content" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="5px">
            
            <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                <mat-label>Nombres</mat-label>
                <input matInput type="text" formControlName="nameField" required
                    placeholder="Porfavor ingrese sus nombres">
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                <mat-label>Apellidos</mat-label>
                <input matInput type="text" formControlName="lastNameField" required
                    placeholder="Porfavor ingrese sus apellidos">
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                <mat-label>Correo Electrionico</mat-label>
                <input matInput type="email" formControlName="emailField" required
                    placeholder="Porfavor ingrese su correo">
                <mat-icon matSuffix aria-hidden="false" fontIcon="mail"></mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                <mat-label>Numero de identificación</mat-label>
                <input matInput type="text" formControlName="identificationField" required
                    placeholder="Porfavor ingrese numero de identificacion">
                <mat-icon matSuffix aria-hidden="false" fontIcon="badge"></mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                <mat-label>Contraseña</mat-label>
                <input matInput type="password" formControlName="passwordField" required
                    placeholder="Ingrese una contraseña">
                <mat-icon matSuffix aria-hidden="false" fontIcon="lock"></mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                <mat-label> Validar Contraseña</mat-label>
                <input matInput type="password" formControlName="confirmationPasswordField" required
                    placeholder="Ingrese su contraseña nuevamente">
                <mat-icon matSuffix aria-hidden="false" fontIcon="lock"></mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                <mat-label>Nro Celular</mat-label>
                <input matInput type="number" formControlName="phoneField" required
                    placeholder="Ingrese su Numero de celular">
                <mat-icon matSuffix aria-hidden="false" fontIcon="phone_enabled"></mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex.gt-xs="100" fxFlex.gt-md="calc(50% - 10px)">
                <mat-label>Seleccione un Rol</mat-label>
                <mat-select formControlName="rolField">
                    <mat-option *ngFor="let item of rolls" [value]="item">
                        {{item.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-card-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close (click)="closeModal()">Cancelar</button>
            <button mat-button cdkFocusInitial class="primary-btn" color="primary" type="submit">Guardar</button>
        </mat-dialog-actions>
    </form>
</mat-card>