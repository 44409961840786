<div class="content-dashboard">
    <div class="title-module">
        <span>Gestor de Ordenes de servicios</span>
    </div>
    <div class="boddy-module" fxLayout="column">
        <div class="status-widgets">
            <app-card-status [dataSource]="dataSouceWidget"> </app-card-status>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
            <div class="card-list" fxFlex="100">
                <mat-card class="example-card">
                    <mat-card-header class="title-modules" fxLayout="row">
                        <div class="title" fxFlex="30">
                            <mat-card-title>Ordenes de servicios</mat-card-title>
                        </div>
                        <div class="actions" fxLayout="row" fxLayoutAlign="end center" fxFlex="80">
                            <mat-form-field class="filter-input" appearance="outline">
                                <mat-label>Filtro</mat-label>
                                <input matInput placeholder="Ingrese un nombre" (keyup)="applyFilter($event)">
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>
                            <div class="add-action" fxLayoutAlign="center center">
                                <button mat-button class="add-btn" fxLayout="row" fxLayoutAlign="center center"
                                    (click)="openManagerService()">
                                    <mat-icon>add_circle</mat-icon>
                                    <span>Nuevo</span>
                                </button>
                            </div>
                            <div class="add-action" fxLayoutAlign="center center">
                                <button mat-button class="add-btn" fxLayout="row" fxLayoutAlign="center center"
                                    (click)="exportReport()">
                                    <mat-icon>download_for_offline</mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-card-header>
                    <mat-card-content>
                        <table class="mat-elevation-z8" mat-table [dataSource]="servicesDataSource" >
                            <ng-container matColumnDef="Solicitante">
                                <th mat-header-cell *matHeaderCellDef> Solicitante </th>
                                <td mat-cell *matCellDef="let element"
                                    [ngClass]="validationStatus(element.serviceStatus.id)"> {{element.applicantUser.applicantName}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Compañia">
                                <th mat-header-cell *matHeaderCellDef> Compañia </th>
                                <td mat-cell *matCellDef="let element"> {{element.applicantCompany.name}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Dirección">
                                <th mat-header-cell *matHeaderCellDef> Dirección </th>
                                <td mat-cell *matCellDef="let element"> {{element.applicantCompany.address}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Telefono">
                                <th mat-header-cell *matHeaderCellDef> Telefono </th>
                                <td mat-cell *matCellDef="let element"> {{element.applicantCompany.phone}}</td>
                            </ng-container>
                            <ng-container matColumnDef="Tipo">
                                <th mat-header-cell *matHeaderCellDef> Tipo </th>
                                <td mat-cell *matCellDef="let element"> {{element.serviceType.name}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Asignado">
                                <th mat-header-cell *matHeaderCellDef> Asignación </th>
                                <td mat-cell *matCellDef="let element"> {{element.assignedUser?.name}}
                                    {{element.assignedUser?.lastName}}</td>
                            </ng-container>
                            <ng-container matColumnDef="Estado">
                                <th mat-header-cell *matHeaderCellDef> Estado: </th>
                                <td mat-cell *matCellDef="let element"> {{element.serviceStatus.name}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Creado">
                                <th mat-header-cell *matHeaderCellDef>Creación</th>
                                <td mat-cell *matCellDef="let element"> {{element.createdDate}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Fecha de Inicio">
                                <th mat-header-cell *matHeaderCellDef>Inicio</th>
                                <td mat-cell *matCellDef="let element"> {{element.date_Start}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Fecha de Final">
                                <th mat-header-cell *matHeaderCellDef>Final</th>
                                <td mat-cell *matCellDef="let element"> {{element.date_End}} </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="servicesDataTitle"></tr>
                            <tr class="example-element-row" mat-row *matRowDef="let row; columns: servicesDataTitle;" (click)="openDetailServiceOrder(row)"></tr>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>

        </div>
    </div>
</div>