import { Component } from '@angular/core';

@Component({
  selector: 'app-external-orders',
  templateUrl: './external-orders.component.html',
  styleUrls: ['./external-orders.component.less']
})
export class ExternalOrdersComponent {

}
