export class CompanieEntitie {
    id : string | undefined;
    name : string | undefined; 
    nit : string | undefined;
    email : string | undefined;
    address : string | undefined;
    phone : string | undefined;
    status : StatusCompanyEntitie | undefined;
    parent_id : string | undefined;
}

export class StatusCompanyEntitie{
    id :  string | undefined;
    name :  string | undefined;
    description : string | undefined;
}