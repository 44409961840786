
<form class="sing-in-form" [formGroup]="singUpForm" >
    <mat-card class="content-form">

      <mat-card-header class="content-title">
        <mat-card-title class="title">Registro de Usuario</mat-card-title>
        <mat-card-subtitle class="subtitle">Ingresa los siguientes datos y comienza una nueva
          experienza</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content class="content-ipunts" fxLayout="column">
        <mat-form-field appearance="outline">
            <mat-label>Nombres:</mat-label>
            <input matInput type="text" formControlName="nameField" required placeholder="Porfavor ingrese sus nombres">
            <mat-icon matSuffix aria-hidden="false" fontIcon="mail"></mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Apellidos:</mat-label>
            <input matInput type="text" formControlName="lastNameField" required placeholder="Porfavor ingrese sus apellidos">
            <mat-icon matSuffix aria-hidden="false" fontIcon="mail"></mat-icon>
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label>Correo electronico</mat-label>
          <input matInput type="email" formControlName="emailField" required placeholder="Porfavor ingrese su correo">
          <mat-icon matSuffix aria-hidden="false" fontIcon="mail"></mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Celular</mat-label>
          <input matInput type="number" formControlName="phoneField" required
            placeholder="Porfavor ingrese su celular">
          <mat-icon matSuffix aria-hidden="false" fontIcon="lock"></mat-icon>
        </mat-form-field>

      </mat-card-content>

      <mat-card-actions class="content-btn" fxLayout="column" fxLayoutAlign="center center">
        <button mat-raised-button color="primary" class="ui-button-primary btn-login" (click)="validationSingupForm()">Registrar</button>
        <button mat-stroked-button color="accent" class="btn-login" (click)="openSingUpByGmail()">Registro Gmail</button>
        <button mat-stroked-button color="warn" class="btn-login" (click)="openSingUpByFacebook()">Registro con Facebook</button>
      </mat-card-actions>
    </mat-card>
  </form>