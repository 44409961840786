import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserAdminComponent } from './user-admin/user-admin.component';
import { CompaniesAdminComponent } from './companies-admin/main/companies-admin.component';
import { MainComponent } from './main/main.component';

const routes: Routes = [
  {
    path: 'admin',
    component : MainComponent,
    children: [
      {
        path: 'users',
        component: UserAdminComponent
      },
      {
        path: 'companies',
        component: CompaniesAdminComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminsRoutingModule { }
