import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CompanieService } from 'src/app/services/companie.service';
import { ProfessionalTypeService } from 'src/app/services/profesional-type.service';
import { ServicesService } from 'src/app/services/services.service';
import { ServiceOrderEntitie } from 'src/app/utilities/entities/service-order.entitie';
import { AlertsHelper } from 'src/app/utilities/helpers/alerts-helper';
import { DateHelper } from 'src/app/utilities/helpers/date-helper';
import { CompanyInterface } from 'src/app/utilities/interfaces/company-interface';
import { professionalTypeInterface } from 'src/app/utilities/interfaces/professional-type-Interface';
import { ServiceTypeInterface } from 'src/app/utilities/interfaces/servicetype-interface';
import { StatusInterface } from 'src/app/utilities/interfaces/status-interface';
import { UserInterface } from 'src/app/utilities/interfaces/user-interface';

@Component({
  selector: 'app-service-order-form',
  templateUrl: './service-order-form.component.html',
  styleUrls: ['./service-order-form.component.less']
})
export class ServiceOrderFormComponent implements OnChanges {

  @Input() companie!: CompanyInterface;
  @Input() userSession: UserInterface | undefined;
  @Input() title: string | undefined;
  @Input() subTitle: string | undefined;
  @Input() buttonName: string | undefined;
  @Output() servicesOrderEvent = new EventEmitter<ServiceOrderEntitie>();

  public serviceOrderFormGroup: FormGroup = this.createServiceOrderFrom();
  public typeService: ServiceTypeInterface[] = [];
  public typeProfessional: professionalTypeInterface[] = [];
  public showAditionalInfo: boolean = false;
  private serviceStatusDefault: StatusInterface | undefined;
  private today: Date = new Date();

  constructor(
    private companieService: CompanieService,
    private servicesService: ServicesService,
    private professionalTypeService: ProfessionalTypeService,
    private alertsHelper: AlertsHelper,
    private dateHelper: DateHelper,
    private dialog: MatDialog,
  ) {

  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companie'] && changes['companie'].currentValue) {
        this.setDefaultsParamters();
        this.getServiceTypeCompanie();
        this.getProfesionalType();
    }
  }

  private createServiceOrderFrom(): FormGroup {
    return this.serviceOrderFormGroup = new FormGroup({
      applicantUserCompanyNameField: new FormControl(''),
      applicantUserNameField: new FormControl(''),
      applicantUserPhoneField: new FormControl(''),
      applicantUserEmailField: new FormControl(''),

      serviceDescriptionField: new FormControl(''),
      serviceTypeField: new FormControl(''),
      serviceTypeProfesionField: new FormControl(''),
      serviceRiskLevelField: new FormControl(''),
      serviceNumbWorkersField: new FormControl(''),
      serviceStartDateField: new FormControl(''),
      serviceEndDateField: new FormControl(''),
      serviceToolsDescriptionField: new FormControl(''),
      serviceMachineryDescriptionField: new FormControl(''),
      serviceTequipmentDescriptionField: new FormControl(''),

      applicantCompanyNameField: new FormControl(''),
      applicantCompanyNitField: new FormControl(''),
      applicantCompanyAdressField: new FormControl(''),
      applicantCompanyCityField: new FormControl(''),
      applicantCompanyPhoneField: new FormControl(''),

      coordinatorNameField: new FormControl(''),
      coordinatorPhoneField: new FormControl(''),
      serviceApplicationScheduleField: new FormControl('')

    });
  }


  private setDefaultsParamters(): void {
    this.serviceStatusDefault = {
      id: 'adbb2fe1-9532-4d02-b092-8fcab759ec38',
      name: 'No Asignado',
      description: 'Estado para servicios no asignados'
    };
  }

  public validationTypeService(): void {
    let typeService = this.serviceOrderFormGroup.value.serviceTypeField;
    if (typeService != null && typeService.id == "7f55e778-fc14-48ac-9102-cc7a74115ad5") {
      this.showAditionalInfo = true;
    } else {
      this.showAditionalInfo = false;
    }
  }

  private getServiceTypeCompanie(): void {
    debugger;
    if (this.companie?.id) {
      this.servicesService.GetServicesTypes({ CompanyId: this.companie?.id }).subscribe({
        next: (res: ServiceTypeInterface[]) => {
          this.typeService = res;
        },
        error: (e) => console.error(
          this.alertsHelper.error(e)
        ),
      })
    }
  }

  private getProfesionalType(): void {
    if (this.companie?.id) {
      this.professionalTypeService.GetProfessionalTypes({ CompanyId: this.companie?.id }).subscribe({
        next: (res: professionalTypeInterface[]) => {
          this.typeProfessional = res;
        },
        error: (e) => console.error(
          this.alertsHelper.error(e)
        ),
      })
    }
  }


  public minDate = (date: Date | null): boolean => {
    const currentDate = date || this.dateHelper.formatDateBasic(new Date());
    return currentDate > this.today;
  }

  public minDateSelect = (date: Date | null): boolean => {
    const currentDate = date || this.dateHelper.formatDateBasic(new Date());
    const dateStartSelect = this.serviceOrderFormGroup.value.serviceStartDateField;
    return currentDate > ((dateStartSelect != "") ? dateStartSelect : this.today);
  }

  public ValidationServiceOrder(): void {
    let form = this.serviceOrderFormGroup;
    if (form.valid) {

      let serviceOrderForm: ServiceOrderEntitie = {
        id: '',
        createdUserId: (this.userSession?.id) ? this.userSession?.id : '',
        createdCompanyId: this.companie?.id,
        description: form.value.serviceDescriptionField,
        applicantUser: {
          applicantName: form.value.applicantUserNameField,
          applicantNameCompany: form.value.applicantUserCompanyNameField,
          applicantPhone: form.value.applicantUserPhoneField,
          applicantEmail: form.value.applicantUserEmailField
        },
        applicantCompany: {
          id: '',
          name: form.value.applicantCompanyNameField,
          nit: form.value.applicantCompanyNitField,
          address: form.value.applicantCompanyAdressField,
          phone: form.value.applicantCompanyCityField,
          status: undefined,
          parentId: undefined
        },
        providingCompany: this.companie,
        serviceType: form.value.serviceTypeField,
        professionalType: form.value.serviceTypeProfesionField,
        assignedUser: undefined,
        dateStart: this.dateHelper.formatDateBasic(form.value.serviceStartDateField),
        dateEnd: this.dateHelper.formatDateBasic(form.value.serviceEndDateField),
        aditionalInformation: (this.showAditionalInfo) ? {
          toolsDescription: form.value.serviceToolsDescriptionField,
          equipmentDescription: form.value.serviceMachineryDescriptionField,
          machineryDescription: form.value.serviceTequipmentDescriptionField,
        } : undefined,
        createdDate: this.dateHelper.formatDate(new Date()),
        updatedDate: undefined,
        coordinatorName: form.value.coordinatorNameField,
        coordinatorPhone: form.value.coordinatorPhoneField,
        numberWorkers: form.value.serviceNumbWorkersField,
        serviceRiskLevel: form.value.serviceRiskLevelField,
        emailNotification: 'morat26@gmail.com',
        serviceStatus: this.serviceStatusDefault,
        serviceApplicationSchedule: form.value.serviceApplicationScheduleField,
        sincronizationDate: undefined
      }

      this.servicesOrderEvent.emit(serviceOrderForm);
    }
  }

  public closeModal(): void {
    this.dialog.closeAll();
  }

}
