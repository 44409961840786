<div class="content-dashboard">
    <div class="title-module">
        <span>Dashboard</span>
    </div>
    <div class="boddy-module" fxLayout="column">
        <div class="status-widgets">
            <app-card-status [dataSource]="dataSouceWidget"> </app-card-status>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
            <div class="card-list" fxFlex="50">
                <mat-card class="example-card">
                    <mat-card-header>
                        <mat-card-title>Listado de Usuarios</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <table mat-table [dataSource]="dataUsers.dataSource">
                            <ng-container matColumnDef="nombres">
                                <th mat-header-cell *matHeaderCellDef> Nombres </th>
                                <td mat-cell *matCellDef="let element"> {{element.nombres}} </td>
                            </ng-container>
                            <ng-container matColumnDef="correo">
                                <th mat-header-cell *matHeaderCellDef> Correo </th>
                                <td mat-cell *matCellDef="let element"> {{element.correo}} </td>
                            </ng-container>
                            <ng-container matColumnDef="roll">
                                <th mat-header-cell *matHeaderCellDef> Roll </th>
                                <td mat-cell *matCellDef="let element"> {{element.roll}} </td>
                            </ng-container>
                            <ng-container matColumnDef="estado">
                                <th mat-header-cell *matHeaderCellDef> Estado </th>
                                <td mat-cell *matCellDef="let element"> {{element.estado}} </td>
                            </ng-container>
                        
                            <tr mat-header-row *matHeaderRowDef="dataUsers.titles"></tr>
                            <tr mat-row *matRowDef="let row; columns: dataUsers.titles;"></tr>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="card-list" fxFlex="50">
                <mat-card class="example-card">
                    <mat-card-header>
                        <mat-card-title>Listado de Servicios</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <table mat-table [dataSource]="dataServices.dataSource">
                            <ng-container matColumnDef="solicitante">
                                <th mat-header-cell *matHeaderCellDef> Solicitante </th>
                                <td mat-cell *matCellDef="let element"> {{element.solicitante}} </td>
                            </ng-container>
                            <ng-container matColumnDef="prestador">
                                <th mat-header-cell *matHeaderCellDef> Prestador </th>
                                <td mat-cell *matCellDef="let element"> {{element.prestador}} </td>
                            </ng-container>
                            <ng-container matColumnDef="direccion">
                                <th mat-header-cell *matHeaderCellDef> Direccion </th>
                                <td mat-cell *matCellDef="let element"> {{element.direccion}} </td>
                            </ng-container>
                            <ng-container matColumnDef="estado">
                                <th mat-header-cell *matHeaderCellDef> Estado </th>
                                <td mat-cell *matCellDef="let element"> {{element.estado}} </td>
                            </ng-container>
                        
                            <tr mat-header-row *matHeaderRowDef="dataServices.titles"></tr>
                            <tr mat-row *matRowDef="let row; columns: dataServices.titles;"></tr>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>