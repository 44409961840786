import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { CompanieService } from 'src/app/services/companie.service';
import { CompanyInterface } from 'src/app/utilities/interfaces/company-interface';
import { StatusWidget } from 'src/app/utilities/interfaces/status-widget';
import { UserInterface } from 'src/app/utilities/interfaces/user-interface';
import { MatTableDataSource, MatTableDataSourcePaginator } from '@angular/material/table';
import { CompanyManagerComponent } from '../modals/company-manager/company-manager.component';
import { MatDialog } from '@angular/material/dialog';
import { CompanyDeletedComponent } from '../modals/company-deleted/company-deleted.component';

@Component({
  selector: 'app-companies-admin',
  templateUrl: './companies-admin.component.html',
  styleUrls: ['./companies-admin.component.less']
})
export class CompaniesAdminComponent {
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  public dataSouceWidget : StatusWidget[] = [];
  public companyDataTitle : string[] = [];
  public companyDataSource: any | undefined ;
  private userData : UserInterface = this.getInfoUser();

  constructor(
    public companieService : CompanieService,
    public dialog: MatDialog,
  ) {}

  async ngAfterViewInit() {
    this.getCompaniesByParentCompany();
    
  }

  private getInfoUser(): UserInterface {
    let user = localStorage.getItem('user');
    console.log(JSON.parse(user!));
    return JSON.parse(user!);
  }

  private getCompaniesByParentCompany() : void{
    let ParentId = this.userData?.company.id;
    this.companieService.GetCompaniesByParentCompany({ParentId : ParentId}).subscribe(
      (res : CompanyInterface[]) => {
        this.generateStatusWidget(res);
        this.companyDataSource = new MatTableDataSource(res);
        this.companyDataSource.paginator = this.paginator;
        this.companyDataTitle = ['Nombre', 'Nit', 'Direccion', 'Telefono','Estado', 'Actions']
      }
    );
  }

  public generateStatusWidget(dataSource : CompanyInterface[]) : void {
    let data : StatusWidget[] = [
      {
        title : 'Total Empresas',
        iconName : 'verified_user',
        value : dataSource.length
      },
      {
        title : 'Empresas Activos',
        iconName : 'how_to_reg',
        value : dataSource.filter(x => x.status?.id == "beafe251-df99-4123-8939-af1a37e349dc").length
      },
      {
        title : 'Empresas Inactivos',
        iconName : 'no_accounts',
        value : dataSource.filter(x => x.status?.id == "31553953-f6da-4f5a-a144-dd67d3822119").length
      }
    ]; 
    this.dataSouceWidget = data;
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.companyDataSource.filter = filterValue.trim().toLowerCase();
  }

  public openManagerCompanies() : void {
    const dialogRef = this.dialog.open(CompanyManagerComponent);
  }

  public openCompanieEditModal(element: any){
    const dialogRef = this.dialog.open(CompanyManagerComponent, {
      data: element
    });
  }

  public openCompanieDeletedModal(element: any){
    const dialogRef = this.dialog.open(CompanyDeletedComponent, {
      data: element
    });
  }
  
}
