import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main/main.component';
import { SidebarComponent } from 'src/app/components/sidebar/sidebar.component';
import { NavbarComponent } from 'src/app/components/navbar/navbar.component';
import { CardStatusComponent } from 'src/app/components/card-status/card-status.component';
import { MaterialModule } from 'src/app/utilities/helpers/material-module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OrderServicesComponent } from './order-services/main/order-services.component';
import { OrderDetailsComponent } from './order-services/modals/order-details/order-details.component';
import { CreateOrderComponent } from './order-services/modals/create-order/create-order.component';
import { ServiceOrderFormComponent } from 'src/app/components/forms/service-order-form/service-order-form.component';

@NgModule({
  declarations: [
    MainComponent,
    NavbarComponent,
    SidebarComponent,
    DashboardComponent,
    CardStatusComponent,
    OrderServicesComponent,
    OrderDetailsComponent,
    CreateOrderComponent,
    ServiceOrderFormComponent
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    MaterialModule,
    FlexLayoutModule
  ],
  exports: [
    CardStatusComponent,
    SidebarComponent,
    NavbarComponent,
    ServiceOrderFormComponent
  ]
})
export class MainModule { }
