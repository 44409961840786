import { Injectable } from "@angular/core";
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
  })

export class AlertsHelper {

    constructor() {}

    public success(messagge : string) : void {
        Swal.fire({
            title: 'Registro Guardado!',
            text: messagge,
            icon: 'success',
        });
    }

    public warning(messagge : string) : void {
        Swal.fire({
            title: 'Ups, Algo no esta bien!',
            text: messagge,
            icon: 'warning',
        });
    }

    public error(messagge : string) : void {
        Swal.fire({
            title: 'Ups,Algo Salio Mal.',
            text: messagge,
            icon: 'error',
        });
    }
  
   
  }